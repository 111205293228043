import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import {Link} from "gatsby";
import Seo from "../../components/Seo";
import pinkDiamonds from '../../images/pink-diamonds.svg';

import birdImg from '../../images/birdeye/hummingbird.png';
import featured from '../../images/birdeye/featured.png';
import palette from '../../images/birdeye/palette.png';
import identify1 from '../../images/birdeye/grackle.png';
import identify2 from '../../images/birdeye/killdeer.png';
import home from '../../images/birdeye/home.png';
import what from '../../images/birdeye/what.png';
import ipad1 from '../../images/birdeye/ipad1.png';
import ipad2 from '../../images/birdeye/ipad2.png';
import phones from '../../images/birdeye/phones.png';

import cta from '../../images/birdeye/cta.png';
import logos from '../../images/birdeye/logos.png';

const BirdPage = () => {
    return (
            <Layout pageName="birdeye">
                <Seo
                        title={'BirdEye Ohio'}
                />
                <Container>
                    <div className="grid grid-cols-1 gap-10">
                        <div className="grid md:grid-cols-6 md:gap-10 content-center md:mb-16">
                            <img src={birdImg} alt="Lawn & Landscape" className="md:col-span-3 w-full"/>
                            <div className="text-left md:col-span-3 lg:col-span-2 mt-4 md:mt-0">
                                <h2 className="text-7xl font-serif">BirdEye Ohio</h2>
                                <h3 className="font-mono text-xl mt-4">Fall 2021</h3>
                                <img src={pinkDiamonds} alt="" className="mt-6"/>
                                <p className="mt-8">Inspired by my enjoyment of birding, along with the desire to see an attractive, clean product that helps users quickly identify birds, I built more than 20 pages from a home page, bird ID page and individual bird pages.</p>
                                <p className="mt-4">Follow through the images below to see how the website evolved from logo, font and color palettes, as well as the bird ID guides.</p>
                                <p className="mt-4 text-xs italic">Note: All images are pulled from different sources online, most bird descriptions are pulled from The Cornell Lab of Ornithology and main copy is written by me.</p>
                            </div>
                        </div>
                        <img src={logos} alt="" className="max-w-full"/>
                        <img src={palette} alt="" className="max-w-full"/>
                        <img src={ipad2} alt="" className="max-w-full"/>
                        <div className="grid md:grid-cols-2 gap-4">
                            <img src={identify1} alt="Birdeye" className="max-w-full"/>
                            <img src={identify2} alt="Birdeye" className="max-w-full"/>
                        </div>
                        <img src={ipad1} alt="" className="max-w-full"/>
                        <div className="grid md:grid-cols-2 gap-4">
                            <img src={home} alt="Birdeye" className="max-w-full"/>
                            <img src={what} alt="Birdeye" className="max-w-full"/>
                        </div>
                        <img src={phones} alt="" className="max-w-full"/>
                        <img src={featured} alt="" className="max-w-full"/>
                        <img src={cta} alt="" className="max-w-full"/>
                        <div className="md:mt-16">
                            <Link to="/work/cannabis-dispensary" className="text-4xl font-serif hover:text-pink transition-colors duration-200">Next Project: Cannabis Dispensary</Link>
                        </div>
                    </div>
                </Container>
            </Layout>
    );
};

export default BirdPage;
